.home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -200px;
}

.home nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.home img {
  width: 200px;
  height: auto;
  border-radius: 8px;
}

.home div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-text h1 {
  font-size: 4rem;
  margin: 0;
}

.home-text h2 {
  font-size: 2rem;
  margin: 0;
  border-right: 2px solid white;
  white-space: nowrap;
  overflow: hidden;
  animation: blink-cursor 0.8s steps(2, start) infinite;
}

@keyframes blink-cursor {
  from {
    border-right-color: white;
  }

  to {
    border-right-color: transparent;
  }
}