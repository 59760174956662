.projects .timeline {
    left: 50%;
    border-left: 3px solid #9191cc;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
}

.projects .timeline .projName {
    color: #e0e0e0;
}

.projects .timeline .projDet {
    color: #a0a0b0;
}

.resTimeLeft {
    text-align: left;
    left: 0;
    padding-left: 20px;
}

.resTimeRight {
    text-align: right;
    left: 50%;
    transform: translateX(-100%);
    padding-right: 20px;
}

/*Overflow-x needs to be hidden. This is done in App.css*/
.next-resTimeLeft {
    text-align: left;
    position: absolute;
    left: 0;
    padding-left: 20px;
}

.next-resTimeRight {
    text-align: right;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    padding-right: 20px;

}

.projects ul {
    list-style-type: none;
}

.button {
    display: inline-block;
    margin: 20px;
    padding: 10px 20px;
    background-color: #6a5acd;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #9d91ea;
    color: #f5f5f5;
}

.tech-icons {
    padding-bottom: 5px;
    flex-direction: row;
    align-items: center;
}

.tech-icon {
    width: 60px;
    height: 60px;
    padding-right: 5px;
    padding-left: 5px;
}

.highlights-list {
    list-style-type: disc;
    padding-left: 10px;
    margin: 10px 0;
    font-size: 1.275rem;
    color: #c6c6d0;
    line-height: 1.6;
}