body {
    margin: 0;
    font-family: 'Helvetica', sans-serif;
    line-height: 1.6;
}

.App {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

section {
    padding: 60px 60px;
    display: flex;
    flex-direction: column;
}

#home {
    min-height: 100vh;
    padding: 100px 20px;
    background: #3a2b2b;
    color: #f5eaea;
    justify-content: center;
    align-items: center;
}

#about {
    background: #372c2f;
    color: #d6c5c8;
}

#portfolio {
    background: #332c33;
    color: #d6cbd6;
}

#projects {
    background: #2f2d37;
    color: #cac5d0;
}

#contact {
    min-height: 70vh;
    background: #2b2d3b;
    color: #eaeaf5;
}

h1 {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 20px;
}

h2 {
    font-size: 2.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
}

p {
    font-size: 1.2rem;
    max-width: 800px;
    display: inline;
    margin-bottom: 20px;
}