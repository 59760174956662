html {
  scroll-behavior: smooth;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  z-index: 1000;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .left, .navbar .right {
  display: flex;
  align-items: center;
}

.navbar .left a {
  font-weight: bold;
  font-size: 22px;
  margin-right: 20px;
}

.navbar .right ul {
  list-style-type: none;
  margin: 0;
  padding: 15px;
  display: flex;
}

.navbar .right li {
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 22px;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.navbar a:hover {
  background-color: #555;
  border-radius: 5px;
}